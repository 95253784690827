<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		id: string;
		height?: string | number;
		width?: string | number;
		itemsCount?: number;
		rows?: number;
		gap?: string;
		prerender?: boolean;
	}>(),
	{
		height: "200px",
		width: "100%",
		itemsCount: 1,
		rows: 1,
		gap: "12px"
	}
);
const targetRef = ref<HTMLElement | null>(null);
const isVisible = ref(false);
const minInheritHeight = ref<number | null>(null);
const height = computed(() => (typeof props.height === "number" ? `${props.height}px` : props.height));
const width = computed(() => (typeof props.width === "number" ? `${props.width}px` : props.width));
const rootMargin = computed(() => `${window?.innerHeight ? window?.innerHeight / 2 : 500}px`);

const { stop } = useIntersectionObserver(
	targetRef,
	([{ isIntersecting, boundingClientRect }]) => {
		if (boundingClientRect?.height && !minInheritHeight.value) {
			minInheritHeight.value = boundingClientRect.height;
		}
		if (isIntersecting) {
			isVisible.value = isIntersecting;
			minInheritHeight.value = null;
			stop();
		}
	},
	{ rootMargin: rootMargin.value, threshold: 0 }
);

const checkElementVisibility = () => {
	setTimeout(() => {
		if (!targetRef.value) {
			return;
		}

		const windowHeight = window?.innerHeight || 0;
		const { top, bottom } = targetRef.value?.getBoundingClientRect();

		if ((top >= 0 && top <= windowHeight) || (bottom >= 0 && bottom <= windowHeight)) {
			isVisible.value = true;
		}
	}, 300);
};

onMounted(() => {
	checkElementVisibility();
});

onBeforeUnmount(() => stop());
</script>
<template>
	<div
		:id="id"
		ref="targetRef"
		:class="{ 'lazy-skeleton': !isVisible && !prerender }"
		:style="minInheritHeight ? { minHeight: minInheritHeight + 'px' } : {}"
	>
		<slot v-if="prerender || isVisible" />

		<!-- TODO - Or maybe pass custom skeletons by slots, to be determined by edge cases -->
		<template v-else>
			<slot name="skeleton-title" />

			<div v-if="itemsCount > 1" class="skeleton-grid">
				<ASkeleton v-for="item in itemsCount" :key="item" :width="width" :height="height" />
			</div>
			<ASkeleton v-else :width="width" :height="height" />
		</template>
	</div>
</template>
<style lang="scss" scoped>
.skeleton-grid {
	overflow: hidden;
	display: grid;
	grid-auto-flow: column dense;
	grid-template-rows: repeat(v-bind(rows), 1fr);
	grid-template-columns: repeat(auto-fill, v-bind(width));
	gap: v-bind(gap);
}
</style>
